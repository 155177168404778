<template>
  <div class="page page--companies">
    <v-row justify="center">
      <v-col cols="12" sm="9" md="5">
        <h1 class="page__title">
          {{ $t('select_company_title') }}
        </h1>
        <p class="page__subtitle">
          {{ $t('select_company_message') }}
        </p>

        <ul class="pkmn-fullwidth-select">
          <li
            class="fullwidth-select__item"
            v-for="company in companies"
            :key="company.id"
            @click="select(company)"
          >
            <div class="select-item__two-column-content">
              <div class="select-item__content-main">
                {{ company.name }}
              </div>
              <v-spacer></v-spacer>
              <v-icon class="select-item__content-icon">
                mdi-chevron-right
              </v-icon>
            </div>
          </li>
        </ul>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import helpers from '@/helpers'
import companyHelpers from '@/helpers/company'

export default {
  name: 'CompaniesManage',
  data() {
    return {
      companies: []
    }
  },
  methods: {
    async loadData() {
      // @todo (Hieu): handle onclick, open home with company
      await helpers.fetchCompanyManagementData()
      var companies = this.$store.state.userCompanies

      // display all companies if user have at least one enterprise admin role
      if (companyHelpers.hasCompanyWithEnterpriseAdminRole(companies)) {
        this.companies = companies
      } else {
        // go to home directly with the first company
        this.goToDashboardPage(companies[0].id)
      }
    },
    select(company) {
      this.goToDashboardPage(company.id)
    },
    goToDashboardPage(companyId) {
      // store the company id
      helpers.saveCurrentCompanyId(companyId)

      // request loading new company data
      this.$store.dispatch('setRequestNewDashboardData', true)

      // move to next page
      this.$router.push({
        path: '/home',
        query: this.$route.query
      })
    }
  },
  async mounted() {
    await this.loadData()
  }
}
</script>
